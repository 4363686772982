import React from 'react';
import Header from "../components/Header";
import {BrowserRouter as Router, Outlet, Route, Routes} from 'react-router-dom';
import MainPage from './MainPage';
import Sidebar from "../components/Sidebar";
import AgentPage from "./AgentsPage";
import LeaderboardPage from './LeaderboardPage';

const IndexPage = () => {
    return (
        <Router>
            <div className="min-h-screen bg-[#111827]">
                {/* 主内容路由 */}
                <div>
                    <Routes>
                        {/* 嵌套路由：包含左侧 Sidebar */}
                        <Route path="/" element={<AgentsLayout/>}>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="agents" element={<AgentPage/>}/>
                            <Route path="/leaderboard" element={<LeaderboardPage/>}/>
                            <Route path="my-bet" element={<div>My Bet Content</div>}/>
                        </Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}
const AgentsLayout = () => {
    return (
        <div className="flex h-screen ">
            {/* 左侧侧边栏 */}
            <div className="w-60 bg-gray-800 text-white" style={{height: "calc(100vh - 6rem)"}}>
                <Header/>
                <Sidebar/>
            </div>

            {/* 右侧主内容区域 */}
            <div className="flex-1 bg-gray-900 text-white p-6 overflow-y-auto">
                <Outlet/>
            </div>
        </div>
    );
};


export default IndexPage;
