import React, {useEffect, useState} from 'react';
import {addDoc, collection, getDocs, getFirestore, query, updateDoc, where} from 'firebase/firestore';
import axios from 'axios';
import {API_URL} from '../apis/http';
import Toast from '../components/Toast';
import {usePrivy, useWallets} from '@privy-io/react-auth';
import {useSendTransaction, useSolanaWallets} from '@privy-io/react-auth/solana';
import {Connection, PublicKey, SystemProgram} from '@solana/web3.js';
import {AnchorProvider, Program, web3} from '@coral-xyz/anchor';
import {uploadImageToStorage} from '../utils/imageUpload';

interface Agent {
  id: string;
  name: string;
  description: string;
  profile_picture?: string;
  wallet_address?: string;
  created_at: number;
  games_played: number;
  traits: string[];
  balance?: number;
}

interface ToastState {
  message: string;
  type: 'success' | 'error' | 'info';
  isVisible: boolean;
}

interface WhitelistEntry {
  wallet_address: string;
  added_at: number;
}

// Treasury wallet address where fees will be sent
const TREASURY_WALLET = '7bC7tdaD3Fbzzi2CBoUwxpoETcTSPv6diAAFFjtXu6Wm';
// Fee amount in SOL
const AGENT_CREATION_FEE = 0.05; // 0.05 SOL

// For convenience, define your program ID as a PublicKey.
const MY_PROGRAM_ID = new web3.PublicKey('8XjTgGzQGKNyBuMTFkG1S8frNrQ1UK6ZBTbMJB78qSPT');

const AgentsPage: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [newAgent, setNewAgent] = useState({
    name: '',
    description: '',
    profile_picture: '',
    wallet_address: '',
    traits: [] as string[],
    transaction_id: '',
  });
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'info',
    isVisible: false
  });

  // Get user wallet from Privy
  const { authenticated, user, login } = usePrivy();
  const { sendTransaction } = useSendTransaction();
  const { wallets: solanaWallets } = useSolanaWallets();
  const { ready, wallets } = useWallets();

  // Get the appropriate wallet address (prioritize Solana wallet if available)
  const userWalletAddress = solanaWallets && solanaWallets.length > 0
    ? solanaWallets[0].address
    : user?.wallet?.address || '';

  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [isCheckingWhitelist, setIsCheckingWhitelist] = useState(false);
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  // Show toast notification
  const showToast = (message: string, type: 'success' | 'error' | 'info') => {
    setToast({
      message,
      type,
      isVisible: true
    });
  };

  // Hide toast notification
  const hideToast = () => {
    setToast(prev => ({
      ...prev,
      isVisible: false
    }));
  };

  // Set wallet address when user is authenticated
  useEffect(() => {
    if (authenticated && userWalletAddress) {
      setNewAgent(prev => ({
        ...prev,
        wallet_address: userWalletAddress
      }));
    }
  }, [authenticated, userWalletAddress]);

  useEffect(() => {
    // Fetch agents from API
    const fetchAgents = async () => {
      try {
        console.log(`${API_URL}/agents`);
        const response = await axios.get(`${API_URL}/agents`);
        // console.log('Fetching agents response:', response.data);
        if (response.data.success) {
          setAgents(response.data.agents);
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
        showToast("Failed to fetch agents", "error");
      }
    };

    fetchAgents();
  }, []);


  // 处理图片上传
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploadingImage(true);
    try {
      // 读取文件为 base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;

        try {
          // 上传图片到存储
          const imageUrl = await uploadImageToStorage(base64String);

          // 更新 agent 状态
          setNewAgent(prev => ({
            ...prev,
            profile_picture: imageUrl
          }));

          showToast("Image uploaded successfully", "success");
        } catch (error) {
          console.error("Error uploading image:", error);
          showToast("Failed to upload image", "error");
        } finally {
          setIsUploadingImage(false);
        }
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error reading file:", error);
      showToast("Failed to read file", "error");
      setIsUploadingImage(false);
    }
  };

  // Process payment for agent creation
  const processPayment = async () => {
    if (!authenticated) {
      showToast("Please connect your wallet first", "error");
      return;
    }

    // Check if Solana wallet is connected
    if (!solanaWallets || solanaWallets.length === 0) {
      showToast("Please connect your Solana wallet first", "error");
      return;
    }

    setIsProcessingPayment(true);
    try {
      if (!authenticated) {
        showToast("Please connect your wallet first", "error");
        return;
      }

      if (!solanaWallets || solanaWallets.length === 0) {
        showToast("Please connect your Solana wallet first", "error");
        return;
      }

      // Validate required fields before payment
      if (!newAgent.name.trim()) {
        showToast("Please enter a name for your agent", "error");
        setIsProcessingPayment(false);
        return;
      }

      if (!newAgent.description.trim()) {
        showToast("Please enter a description for your agent", "error");
        setIsProcessingPayment(false);
        return;
      }

      if (!newAgent.profile_picture.trim()) {
        showToast("Please upload an agent profile picture", "error");
        setIsProcessingPayment(false);
        return;
      }

      if (newAgent.traits.length === 0) {
        showToast("Please select at least one trait", "error");
        setIsProcessingPayment(false);
        return;
      }

      // ✅ ✅ ✅ now enter creating the agent throuhg our program
      const program = await getAnchorProgram();

      const [feeConfigPDA] = await PublicKey.findProgramAddress(
        [Buffer.from("fee_config")], // Hardcoded seed
        program.programId
      );

      const [treasuryPDA] = await PublicKey.findProgramAddress(
        [Buffer.from("treasury"), feeConfigPDA.toBuffer()],
        program.programId
      );
      let feeConfig = await program.account["feeConfig"].fetch(feeConfigPDA);

      console.log("Connected wallet address:", solanaWallets[0].address);
      const agentKeypair = web3.Keypair.generate(); // ✅ Generate new agent keypair

      const txSig = await program.methods
        .createAgent(
          "Test Agent",
          "https://example.com/agent",
          "Just a simple agent",
          "gpt-4o-mini"
        )
        .accounts({
          agent: agentKeypair.publicKey,
          feeConfig: feeConfigPDA,
          payer: new PublicKey(solanaWallets[0].address),
          treasury: feeConfig.treasury, // Use the updated treasury address
          systemProgram: SystemProgram.programId,
        })
        .signers([agentKeypair])
        .rpc();

      console.log("Anchor transaction signature:", txSig);
      showToast(`Anchor program invoked successfully! TX: ${txSig}`, "success");

      // Store transaction ID
      setNewAgent(prev => ({
        ...prev,
        transaction_id: txSig,
        wallet_address: solanaWallets[0].address
      }));

      showToast("Payment successful! Creating your agent...", "success");
      // Now create the agent with the transaction signature
      await handleCreateAgent(txSig);

    } catch (error) {
      console.error("Payment error:", error);

      // Check for wallet authorization error
      if (typeof error === 'object' && error !== null && 'message' in error &&
          typeof error.message === 'string' && error.message.includes("has not been authorized by the user")) {
        showToast("Please approve the transaction in your wallet", "info");

        // Prompt to connect wallet again to ensure full authentication
        if (solanaWallets && solanaWallets.length > 0) {
          try {
            await solanaWallets[0].loginOrLink();
            showToast("Wallet reconnected. Please try the transaction again.", "success");
          } catch (connectError) {
            console.error("Wallet reconnection failed:", connectError);
            showToast("Failed to reconnect wallet. Please try again.", "error");
          }
        }
      } else {
        showToast(`Payment failed: ${(error as Error).message || "Unknown error"}`, "error");
      }
    } finally {
      setIsProcessingPayment(false);
    }
  };

  const handleCreateAgent = async (transactionId: string) => {
    try {
      // Ensure wallet address is set to Solana wallet if available
      const walletToUse = solanaWallets && solanaWallets.length > 0
        ? solanaWallets[0].address
        : userWalletAddress;

      if (!newAgent.wallet_address) {
        setNewAgent(prev => ({
          ...prev,
          wallet_address: walletToUse
        }));
      }

      const agentData = {
        ...newAgent,
        transaction_id: transactionId,
        created_at: Date.now(),
        games_played: 0
      };

      // Send agent data to API
      const response = await axios.post(`${API_URL}/agent/create`, agentData);

      if (response.data.success) {
        showToast("Agent created successfully!", "success");

        // Fetch updated agents list
        const updatedResponse = await axios.get(`${API_URL}/agents`);
        if (updatedResponse.data.success) {
          setAgents(updatedResponse.data.agents);
        }

        // Reset form and close creation panel
        setNewAgent({
          name: '',
          description: '',
          profile_picture: '',
          wallet_address: '',
          traits: [],
          transaction_id: ''
        });
        setIsCreating(false);
      } else {
        throw new Error(response.data.message || "Failed to create agent");
      }
    } catch (error) {
      console.error("Error creating agent:", error);
      showToast("Failed to create agent", "error");
    }
  };

  const handleDeleteAgent = async (agentId: string) => {
    try {
      const response = await axios.delete(`${API_URL}/agents/${agentId}`);

      if (response.data.success) {
        showToast("Agent deleted successfully", "success");

        // Update local state to remove the deleted agent
        setAgents(agents.filter(agent => agent.id !== agentId));
      } else {
        throw new Error(response.data.message || "Failed to delete agent");
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
      showToast("Failed to delete agent", "error");
    }
  };

  const availableTraits = [
    "Aggressive", "Conservative", "Bluffer", "Analytical",
    "Unpredictable", "Tight", "Loose", "Passive", "Observant"
  ];

  const checkWhitelist = async (walletAddress: string) => {
    setIsCheckingWhitelist(true);
    try {
      const db = getFirestore();
      const whitelistRef = collection(db, 'whitelist');
      const q = query(whitelistRef, where('wallet_address', '==', walletAddress));
      const querySnapshot = await getDocs(q);

      setIsWhitelisted(!querySnapshot.empty);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking whitelist:', error);
      return false;
    } finally {
      setIsCheckingWhitelist(false);
    }
  };

  const handleInviteCode = async () => {
    if (!inviteCode.trim()) {
      showToast('Please enter an invitation code', 'error');
      return;
    }

    setIsSubmittingCode(true);
    try {
      // Check the invite code against your valid codes
      const db = getFirestore();
      const inviteCodesRef = collection(db, 'inviteCodes');
      const q = query(inviteCodesRef, where('inviteCode', '==', inviteCode.trim()), where('isUsed', '==', false));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        showToast('Invalid or expired invitation code', 'error');
        return;
      }

      // Add user to whitelist
      const whitelistRef = collection(db, 'whitelist');
      await addDoc(whitelistRef, {
        wallet_address: userWalletAddress,
        added_at: Date.now(),
        invite_code_used: inviteCode.trim()
      });

      // Mark invite code as used
      const inviteCodeDoc = querySnapshot.docs[0];
      await updateDoc(inviteCodeDoc.ref, {
        used: true,
        used_by: userWalletAddress,
        used_at: Date.now()
      });

      setIsWhitelisted(true);
      setShowInviteModal(false);
      showToast('Successfully added to whitelist!', 'success');
      setIsCreating(true);
    } catch (error) {
      console.error('Error processing invite code:', error);
      showToast('Error processing invitation code', 'error');
    } finally {
      setIsSubmittingCode(false);
    }
  };

  const handleCreateAgentClick = async () => {
    console.log("creating agent");
    if (!authenticated) {
      showToast('Please connect your wallet first', 'error');
      return;
    }

    const whitelisted = await checkWhitelist(userWalletAddress);
    if (whitelisted) {
      setIsCreating(true);
    } else {
      setShowInviteModal(true);
    }
  };

  // 2. A helper function to create an Anchor program object
  const getAnchorProgram = async () => {
    // We'll assume you already have a connection
    const connection = new Connection(
      process.env.REACT_APP_SOLANA_RPC_URL || 'https://frosty-intensive-meadow.solana-mainnet.quiknode.pro/7042461ad04097d8f4dd765998db7cdd3a613603/',
      'confirmed'
    );

    // We'll assume you have a solanaWallets[0] with address + signTransaction
    const walletAdapter = {
      publicKey: new PublicKey(solanaWallets[0].address),
      signTransaction: solanaWallets[0].signTransaction,
      signAllTransactions: solanaWallets[0].signAllTransactions,
    };

    // Create Anchor Provider
    const provider = new AnchorProvider(connection, walletAdapter as any, {
      preflightCommitment: 'processed',
    });
    const idl = require("../contracts/agent_no_config.json");

    // Create the program instance -> the program idl
    const program = new Program(idl, provider);
    return program;
  };


  return (
    <div className="p-6">
      <Toast
        message={toast.message}
        type={toast.type}
        isVisible={toast.isVisible}
        onClose={hideToast}
      />

      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-white">Agent Dashboard</h1>
          {authenticated ? (
            !isCreating && (
              <button
                onClick={handleCreateAgentClick}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md flex items-center gap-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                Create Agent
              </button>
            )
          ) : (
            <div className="text-gray-400 flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Connect wallet to create agents
            </div>
          )}
        </div>

        {isCreating && (
          <div className="bg-gray-800 rounded-lg p-6 mb-8 border border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">Create New Agent</h2>
            </div>

            {/* Payment notice banner */}
            <div className="mb-6 p-3 bg-yellow-900/30 border border-yellow-700/50 rounded-md flex items-center gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <div>
                <p className="text-yellow-300 font-medium">Agent Creation Fee: {AGENT_CREATION_FEE} SOL</p>
                <p className="text-yellow-200/70 text-sm">You'll be prompted to approve this transaction after filling out the form.</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-1">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={newAgent.name}
                  onChange={(e) => setNewAgent({...newAgent, name: e.target.value})}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  placeholder="Agent name"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-1">
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  value={newAgent.description}
                  onChange={(e) => setNewAgent({...newAgent, description: e.target.value})}
                  className="w-full p-2 bg-gray-700 text-white rounded h-24"
                  placeholder="Describe your agent's personality and strategy"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-1">
                  Profile Picture <span className="text-red-500">*</span>
                </label>
                <div className="space-y-2">
                  <div className="flex items-center gap-4">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                      id="profile-picture-upload"
                      disabled={isUploadingImage}
                    />
                    <label
                      htmlFor="profile-picture-upload"
                      className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded cursor-pointer flex items-center gap-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                      </svg>
                      {isUploadingImage ? "Uploading..." : "Choose Image"}
                    </label>
                    {newAgent.profile_picture && (
                      <img
                        src={newAgent.profile_picture}
                        alt="Preview"
                        className="w-12 h-12 rounded object-cover"
                      />
                    )}
                  </div>
                  <p className="text-xs text-gray-500">Supports JPG and PNG formats</p>
                </div>
              </div>

              <div>
                <label className="block text-gray-300 mb-1">
                  Creator Wallet Address
                </label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={solanaWallets && solanaWallets.length > 0
                      ? solanaWallets[0].address
                      : (newAgent.wallet_address || userWalletAddress)}
                    readOnly
                    className="w-full p-2 bg-gray-700 text-white rounded opacity-75 cursor-not-allowed"
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  {solanaWallets && solanaWallets.length > 0
                    ? "Using your connected Solana wallet address"
                    : "Using your connected wallet address"}
                </p>
              </div>

              <div>
                <label className="block text-gray-300 mb-1">
                  Traits <span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-2">
                  {availableTraits.map(trait => (
                    <div key={trait} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`trait-${trait}`}
                        checked={newAgent.traits.includes(trait)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setNewAgent({...newAgent, traits: [...newAgent.traits, trait]});
                          } else {
                            setNewAgent({...newAgent, traits: newAgent.traits.filter(t => t !== trait)});
                          }
                        }}
                        className="mr-2"
                      />
                      <label htmlFor={`trait-${trait}`} className="text-gray-300 text-sm">
                        {trait}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-6 gap-4">
              <button
                onClick={() => setIsCreating(false)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={processPayment}
                // disabled={isProcessingPayment || !solanaWallets || solanaWallets.length === 0}
                className={`px-4 py-2 bg-gradient-to-r from-emerald-600 to-teal-600 hover:from-emerald-700 hover:to-teal-700 text-white rounded-md flex items-center gap-2 
                }`}
              >
                {isProcessingPayment ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing Payment...
                  </>
                ) : (
                  <>
                    Create Agent
                  </>
                )}
              </button>
            </div>
          </div>
        )}

        {/* Invitation Code Modal */}
        {showInviteModal && (
          <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md border border-gray-700">
              <h2 className="text-xl font-bold text-white mb-4">Enter Invitation Code</h2>
              <p className="text-gray-400 text-sm mb-4">
                Please enter your invitation code to create an agent. If you don't have one, please contact an administrator.
              </p>

              <input
                type="text"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                placeholder="Enter your code"
                className="w-full p-3 bg-gray-700 text-white rounded-md border border-gray-600 focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 mb-4"
              />

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowInviteModal(false)}
                  className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleInviteCode}
                  disabled={isSubmittingCode}
                  className="px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 text-white rounded-md flex items-center gap-2"
                >
                  {isSubmittingCode ? (
                    <>
                      <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Verifying...
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {agents.map(agent => (
            <div key={agent.id}
              className="relative bg-gray-900/90 backdrop-blur-xl rounded-lg overflow-hidden border border-gray-800 hover:border-cyan-500/50 transition-all duration-300 group"
            >
              {/* Cyberpunk scanning effect */}
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-cyan-500/5 to-transparent translate-y-[-100%] group-hover:translate-y-[100%] transition-transform duration-1000" />

              {/* System header with holographic effect */}
              <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 p-3 border-b border-cyan-800/30">
                <div className="flex items-center gap-3">
                  <div className="relative">
                    {/* Holographic glow */}
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-cyan-500 to-purple-600 opacity-30 blur group-hover:opacity-50 transition-opacity duration-300" />

                    {agent.profile_picture ? (
                      <img
                        src={agent.profile_picture}
                        alt={agent.name}
                        className="w-12 h-12 rounded-lg object-cover border border-cyan-500/20 relative z-10"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`;
                        }}
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-cyan-500 to-purple-600 flex items-center justify-center text-white text-xl font-bold relative z-10">
                        {agent.name.charAt(0).toUpperCase()}
                      </div>
                    )}

                    {/* Status indicator */}
                    <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-cyan-500 rounded-full border border-gray-900 z-20">
                      <div className="absolute inset-0 bg-cyan-500 rounded-full animate-ping opacity-75" />
                    </div>
                  </div>

                  <div className="flex-1 min-w-0">
                    <h3 className="text-base font-bold text-white truncate">{agent.name}</h3>
                    <div className="flex items-center gap-2">
                      <span className="px-1.5 py-0.5 bg-cyan-500/10 rounded text-[10px] text-cyan-300 border border-cyan-500/20">
                        SYSTEM v1.0
                      </span>
                      {agent.games_played > 0 && (
                        <span className="px-1.5 py-0.5 bg-purple-500/10 rounded text-[10px] text-purple-300 border border-purple-500/20">
                          {agent.games_played}G
                        </span>
                      )}
                    </div>
                  </div>

                  {/* System metrics */}
                  <div className="text-right">
                    <div className="text-xl font-bold bg-gradient-to-r from-cyan-400 to-teal-300 bg-clip-text text-transparent">
                        {agent.balance || 0}
                    </div>
                    <div className="flex items-center justify-end gap-1 mt-0.5">
                      <span className="h-1.5 w-1.5 bg-cyan-500 rounded-full animate-pulse" />
                      <span className="text-[10px] text-cyan-400">ONLINE</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Quick stats & controls */}
              <div className="p-3 space-y-2">
                {/* Description with tech overlay */}
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/5 to-purple-500/5 rounded" />
                  <p className="text-xs text-gray-400 line-clamp-2 relative">
                    {agent.description}
                  </p>
                </div>

                {/* Traits as system tags */}
                <div className="flex flex-wrap gap-1.5">
                  {agent.traits.map((trait, index) => (
                    <span
                      key={index}
                      className="px-1.5 py-0.5 bg-gray-800 rounded text-[10px] text-gray-400 border border-gray-700 hover:border-cyan-500/50 transition-colors"
                    >
                      {trait}
                    </span>
                  ))}
                </div>

                {/* System footer */}
                <div className="flex items-center justify-between pt-2 border-t border-gray-800">
                  <div className="flex items-center gap-2 text-[10px] text-gray-500">
                    <span className="font-mono">SYS::ACTIVE</span>
                    <span className="w-4 h-px bg-cyan-500/50 animate-pulse" />
                    <span className="font-mono">READY</span>
                  </div>
                  <button
                    onClick={() => handleDeleteAgent(agent.id)}
                    className="text-red-400/70 hover:text-red-400 transition-colors"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}

          {agents.length === 0 && !isCreating && (
            <div className="col-span-full text-center py-12 text-gray-400 bg-gray-800/50 rounded-lg border border-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gray-600 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              <p className="text-xl">No agents found</p>
              <p className="mt-2">Create your first AI poker agent to get started</p>

              {authenticated ? (
                <button
                  onClick={handleCreateAgentClick}
                  className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
                >
                  Create Agent
                </button>
              ) : (
                <div className="mt-4 text-sm text-gray-500">
                  Connect your Solana wallet to create agents
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentsPage;
