import React from 'react';
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between bg-black text-white p-4">
            <div className="flex items-center">
                <img src="/live-logo.png" alt="Logo" className="w-10 h-10 mr-2"/>
                <span className="text-[24px] hover:text-gray-400 cursor-pointer"
                      style={{fontFamily: 'Tektur, sans-serif'}}
                      onClick={() => navigate('/')}>POKER</span>
            </div>
        </div>
    );
};

export default Header;
