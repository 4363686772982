import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {db} from '../config/firebaseConfig';
import {collection, doc, getDocs, limit, onSnapshot, orderBy, query, where} from 'firebase/firestore';
import Tutorials from "./Tutorials";
import {ActionInfo, Card, ChatMessage, GameState} from "../types/game";


const MESSAGE_DISPLAY_DURATION = 10000; // 10 seconds in milliseconds
const MESSAGE_CHARACTER_LIMIT = 60; // Maximum characters to display in chat messages

// Add this helper function at the component level
const getActionText = (action: ActionInfo): string => {
    switch (action.action_type) {
        case 'FOLD':
            return 'Folded';
        case 'CALL':
            return action.amount > 0 ? `Called $${action.amount}` : 'Checked';
        case 'RAISE':
            return `Raised to $${action.amount}`;
        default:
            return `${action.action_type} $${action.amount}`;
    }
};

// Add this helper function to truncate messages
const truncateMessage = (message: string): string => {
    return message.length > MESSAGE_CHARACTER_LIMIT
        ? `${message.substring(0, MESSAGE_CHARACTER_LIMIT)}...`
        : message;
};

// Add helper function to determine if player is on top
const isTopPlayer = (position: string): boolean => {
    // 检查位置字符串是否包含 'top'
    return position.includes('top');
};

const PokerTablePanel: React.FC = () => {
    const [gameState, setGameState] = useState<GameState | null>(null);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    // Add a ref to track previous chat messages for comparison
    const chatMessagesRef = React.useRef<ChatMessage[]>([]);
    // Add this state to track which messages should be visible
    const [visibleMessages, setVisibleMessages] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        // Get the latest active game
        const gamesQuery = query(
            collection(db, 'poker/prod/poker_games'),
            where('status', '==', 'active'),
            orderBy('last_updated', 'desc'),
            limit(1)
        );

        console.log('Querying for latest active game...');
        getDocs(gamesQuery).then((querySnapshot) => {
            console.log('Query results:', querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })));
        }).catch((error) => {
            console.error('Error getting documents:', error);
        });

        // Listen for the latest active game
        const unsubscribeGames = onSnapshot(gamesQuery, (snapshot) => {
            if (!snapshot.empty) {
                const latestGame = snapshot.docs[0];
                const gameId = latestGame.id;
                // console.log('Latest active game ID:', gameId);

                // Now listen to this specific game's state
                const gameStateQuery = doc(db, `poker/prod/poker_games/${gameId}`);

                const unsubscribeGameState = onSnapshot(gameStateQuery, (stateSnapshot) => {
                    if (stateSnapshot.exists()) {
                        const currentState = stateSnapshot.data().current_state as GameState;
                        // console.log('New game state received:', currentState);
                        currentState.status = stateSnapshot.data().status
                        setGameState(currentState);

                        // Update chat messages from agent_messages
                        if (currentState.agent_messages) {
                            setChatMessages(currentState.agent_messages.map(msg => ({
                                player_id: msg.player_id,
                                message: msg.message,
                                timestamp: msg.timestamp,
                                type: msg.message_type
                            })));
                        }
                    }
                });

                // Return cleanup function
                return () => {
                    unsubscribeGameState();
                };
            }
        });

        // Return cleanup function for games listener
        return () => {
            unsubscribeGames();
        };
    }, []);

    // Replace the chat message handling with a more robust approach
    useEffect(() => {
        if (!gameState) return;

        const gameId = gameState.game_id;

        // Listen to the game_chats collection for real-time chat messages
        const chatQuery = query(
            collection(db, 'poker/prod/game_chats', gameId, 'messages'),
            orderBy('timestamp', 'desc'),
            limit(10)
        );

        // Create a map to track message visibility timeouts
        const timeoutMap = new Map<string, NodeJS.Timeout>();

        const unsubscribeChat = onSnapshot(chatQuery, (chatSnapshot) => {
            // Get only new messages since last snapshot
            const messages = chatSnapshot.docs.map(doc => doc.data() as ChatMessage);

            // Find truly new messages by comparing with our ref
            const newMessages = messages.filter(msg =>
                !chatMessagesRef.current.some(existingMsg => existingMsg.timestamp === msg.timestamp)
            );

            if (newMessages.length > 0) {
                console.log('New chat messages:', newMessages);

                // Update chat messages state with all messages
                setChatMessages(messages);

                // Only process visibility for new messages
                newMessages.forEach(msg => {
                    const msgKey = `${msg.player_id}-${msg.timestamp}`;

                    // Clear any existing timeout for this message
                    if (timeoutMap.has(msgKey)) {
                        clearTimeout(timeoutMap.get(msgKey)!);
                    }

                    // Make message visible
                    setVisibleMessages(prev => ({...prev, [msgKey]: true}));

                    // Set timeout to hide message
                    const timeout = setTimeout(() => {
                        setVisibleMessages(prev => ({...prev, [msgKey]: false}));
                        timeoutMap.delete(msgKey);
                    }, MESSAGE_DISPLAY_DURATION);

                    // Store timeout reference
                    timeoutMap.set(msgKey, timeout);
                });
            }

            // Update ref for comparison on next update
            chatMessagesRef.current = messages;
        });

        // Clean up timeouts on unmount
        return () => {
            unsubscribeChat();
            timeoutMap.forEach(timeout => clearTimeout(timeout));
        };
    }, [gameState?.game_id]);

    const formatCard = (card: Card) => {
        // Log the card data to see what we're working with
        // console.log("Card data:", card);

        // Determine if it's a red suit (hearts or diamonds)
        const isRedSuit =
            card.suit === '♥' || card.suit === '♦' ||
            card.suit === 'hearts' || card.suit === 'diamonds' ||
            card.suit === 'H' || card.suit === 'D' ||
            card.suit === 'h' || card.suit === 'd' ||
            card.suit.toLowerCase().includes('heart') ||
            card.suit.toLowerCase().includes('diamond');

        // Convert single letter suits to symbols for display
        let displaySuit = card.suit;
        if (card.suit === 'H' || card.suit === 'h') displaySuit = '♥';
        if (card.suit === 'D' || card.suit === 'd') displaySuit = '♦';
        if (card.suit === 'S' || card.suit === 's') displaySuit = '♠';
        if (card.suit === 'C' || card.suit === 'c') displaySuit = '♣';

        // Apply the appropriate color class with more explicit styling
        return (
            <span style={{color: isRedSuit ? '#e53e3e' : '#000000'}}>
                {displaySuit}{card.rank}
            </span>
        );
    };


    return (
        <div className="w-full h-full flex flex-col">
            {/* Table Container */}
            <div className="relative w-full flex-1">
                {/* The actual table */}
                <div className="absolute inset-0 mr-6 my-4 bg-emerald-800 rounded-[40px]"
                     style={{maxWidth: '1200px'}}>
                    {/* Community Cards */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-4">
                        {gameState?.community_cards.map((card, index) => (
                            <motion.div
                                key={index}
                                initial={{scale: 0}}
                                animate={{scale: 1}}
                                className="w-24 h-36 bg-white rounded-xl flex items-center justify-center text-4xl text-black font-bold shadow-lg"
                            >
                                {formatCard(card)}
                            </motion.div>
                        ))}
                    </div>

                    {/* Players */}
                    {gameState?.players.map((player) => (
                        <motion.div
                            key={player.player_id}
                            className={`absolute ${getPlayerPosition(player.player_id)}`}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                        >
                            {/* Chat Bubble */}
                            {chatMessages
                                .filter(msg => {
                                    // Only show messages from this player
                                    if (msg.player_id !== player.player_id) return false;

                                    // Check if message is marked as visible in our state
                                    const msgKey = `${msg.player_id}-${msg.timestamp}`;

                                    // Use visibility state only, don't rely on timestamp comparison
                                    return visibleMessages[msgKey];
                                })
                                .slice(0, 1) // Only show the most recent message
                                .map(msg => (
                                    <motion.div
                                        key={msg.timestamp}
                                        initial={{scale: 0, opacity: 0}}
                                        animate={{scale: 1, opacity: 1}}
                                        exit={{scale: 0, opacity: 0}}
                                        transition={{type: "spring", duration: 0.5}}
                                        className={`absolute ${isTopPlayer(getPlayerPosition(player.player_id)) ? '-bottom-16' : '-top-16'} left-0 z-10`}
                                    >
                                        <div className={`relative px-3 py-1 rounded-xl max-w-[300px] break-words bg-[#C8C8C8]/50 text-white`}>
                                            {/* Triangle pointer - direction based on player position */}
                                            {isTopPlayer(getPlayerPosition(player.player_id)) ? (
                                                <div className="absolute top-[-8px] left-1/2 transform -translate-x-1/2
                                                      w-0 h-0 border-l-[8px] border-l-transparent
                                                      border-b-[8px] border-b-white/10
                                                      border-r-[8px] border-r-transparent">
                                                </div>
                                            ) : (
                                                <div className="absolute bottom-[-8px] left-1/2 transform -translate-x-1/2
                                                      w-0 h-0 border-l-[8px] border-l-transparent
                                                      border-t-[8px] border-t-white/10
                                                      border-r-[8px] border-r-transparent">
                                                </div>
                                            )}
                                            <div className="text-xs">
                                                {truncateMessage(msg.message)}
                                            </div>
                                        </div>
                                    </motion.div>
                                ))
                            }

                            <div className="flex items-center gap-4">
                                <div className="flex flex-col items-center">
                                    <img
                                        src={player.profile_picture || `https://api.dicebear.com/7.x/pixel-art/svg?seed=Player${player.player_id}`}
                                        className={`w-12 h-12 rounded-full bg-gray-700 ${
                                            player.player_id === gameState?.active_player_id
                                                ? 'ring-2 ring-yellow-400 animate-pulse'
                                                : ''
                                        }`}
                                        alt={`Player ${player.player_id}`}
                                    />
                                    {player.hole_cards.length > 0 && (
                                        <div className="flex gap-2 mt-2">
                                            {player.hole_cards.map((card, index) => (
                                                <div key={index}
                                                     className="w-12 h-16 bg-white rounded-lg flex items-center justify-center text-xl text-black font-bold shadow-md">
                                                    {formatCard(card)}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="text-sm">
                                    <div className="text-white font-bold">
                                        {player.name || `Player ${player.player_id}`}
                                    </div>
                                    <div className="text-[#FE6868]">NW: ${player.balance}</div>

                                    {player.current_bet > 0 && (
                                        <div className="text-blue-400">Bet: ${player.current_bet}</div>
                                    )}

                                    {/* Show player action status - recent actions are animated, past ones are static */}
                                    {gameState?.last_action && gameState.last_action.player_id === player.player_id ? (
                                        <div className="text-yellow-400 font-semibold animate-pulse">
                                            {getActionText(gameState.last_action)}
                                        </div>
                                    ) : player.is_active === false && (
                                        <div className="text-gray-400">
                                            Folded
                                        </div>
                                    )}

                                    {player.position && (
                                        <div className="text-gray-400 text-xs">{player.position}</div>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* Game tutorials container */}
            <div className="relative">
                {/* Tutorial toggle button */}
                <Tutorials/>
            </div>
        </div>
    );
};

const getPlayerPosition = (position: number): string => {
    const positions = {
        0: 'bottom-[5%] left-[3%]',      // Bottom left
        1: 'bottom-[5%] left-[25%]',     // Bottom center-left
        2: 'bottom-[5%] right-[25%]',    // Bottom center-right
        3: 'bottom-[5%] right-[3%]',     // Bottom right
        4: 'top-[5%] right-[3%]',        // Top right
        5: 'top-[5%] right-[25%]',       // Top center-right
        6: 'top-[5%] left-[25%]',        // Top center-left
        7: 'top-[5%] left-[3%]',         // Top left
        8: 'top-[40%] left-[3%]',        // Middle left
        9: 'top-[40%] right-[3%]',       // Middle right
    };
    return positions[position as keyof typeof positions] || '';
};

// Then update the LastActionDisplay component to use this shared function
const LastActionDisplay: React.FC<{ lastAction: ActionInfo | undefined }> = ({lastAction}) => {
    if (!lastAction) return null;

    return (
        <div className="mt-2 px-3 py-1 bg-gray-800/50 rounded-md border border-gray-700 text-sm">
            <span className="text-yellow-400">Last Action: </span>
            <span className="text-white">{getActionText(lastAction)}</span>
        </div>
    );
};

export default PokerTablePanel;
