import React from 'react';
import {PrivyProvider} from '@privy-io/react-auth';
import './index.css';
import IndexPage from "./pages/IndexPage";
import {ConfigProvider} from "antd";
import {toSolanaWalletConnectors} from '@privy-io/react-auth/solana';
import {Buffer} from 'buffer';
// @ts-ignore
window.Buffer = Buffer;


const theme = {
    token: {
        colorPrimary: '#a062d4',
    },
};

function App() {
    const solanaConnectors = toSolanaWalletConnectors({
        shouldAutoConnect: false,
    });

    return (
        <PrivyProvider
            appId="cm0sjfvwo06zn38au3gawxsb1"
            config={{
                loginMethods: ['wallet'],
                appearance: {
                    walletChainType: 'solana-only',
                },
                externalWallets: {
                    solana: {
                        connectors: solanaConnectors,
                    },
                },
            }}
        >
            <ConfigProvider theme={theme}>
                <IndexPage/>
            </ConfigProvider>
        </PrivyProvider>
    );
}

export default App;
