import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BarChart2, Users} from "lucide-react";
import {usePrivy} from "@privy-io/react-auth";
import {Tooltip} from "antd";
import {useSolanaWallets} from '@privy-io/react-auth/solana';

const Sidebar = () => {
    const location = useLocation();
    const {logout, authenticated, ready, user, login} = usePrivy();
    const { connectWallet } = usePrivy();
    const { wallets: solanaWallets } = useSolanaWallets();
    const [isConnecting, setIsConnecting] = useState(false);

    // Menu items
    const menuItems = [
        {name: "LIVE", path: "/", icon: <BarChart2 className="w-6 h-6 rotate-90"/>, disable: false},
        {name: "Leaderboard", path: "/leaderboard", icon: <BarChart2 className="w-6 h-6"/>, disable: false},
        {name: "Agents", path: "/agents", icon: <Users className="w-6 h-6"/>, disable: false},
    ];

    // Get chain address
    const chainAddress = user?.wallet?.address ||
                       (solanaWallets && solanaWallets.length > 0 ? solanaWallets[0].address : "");
    const displayAddress = chainAddress ? chainAddress.slice(0, 3) : "N/A";

    const isActive = (path: string) => {
        return location.pathname === path;
    };

    // Log connected wallets for debugging
    useEffect(() => {
        console.log("Connected Solana wallets:", solanaWallets);
        console.log("Authenticated:", authenticated);

        if (isConnecting && solanaWallets && solanaWallets.length > 0) {
            setIsConnecting(false);
        }
    }, [solanaWallets, authenticated, isConnecting]);

    // Single-step login with Solana
    const handleWalletAction = async () => {
        // await solanaWallets[0].loginOrLink();
        await login({
            loginMethods:["wallet"],
            walletChainType: "solana-only"
        });

        // try {
        //     setIsConnecting(true);
        //     console.log(solanaWallets)

        //     // Check if user already has Solana wallets connected
        //     if (solanaWallets && solanaWallets.length > 0) {
        //         // If wallet is already connected, try to use it for authentication
        //         await solanaWallets[0].loginOrLink();
        //         console.log("Authenticated with existing Solana wallet");
        //     } else {
        //         // If no wallet is connected, open the connect wallet modal
        //         await connectWallet({ walletChainType: 'solana-only' });
        //     }
        //     // connectWallet();

        // } catch (error) {
        //     console.error("Error during wallet action:", error);
        //     setIsConnecting(false);
        // }
    };


    // Handle user authentication after wallet connection
    // useEffect(() => {
    //     // Check if user has connected a wallet but is not yet fully authenticated
    //     if (solanaWallets && solanaWallets.length > 0 && !authenticated) {
    //         console.log("Wallet connected but not authenticated, attempting to authenticate user");

    //         const authenticateUser = async () => {
    //             try {
    //                 // If the user has a connected wallet but isn't authenticated,
    //                 // we need to complete the authentication process
    //                 await solanaWallets[0].loginOrLink();

    //                 console.log("User authenticated successfully");
    //             } catch (error) {
    //                 console.error("Failed to authenticate user:", error);
    //             }
    //         };

    //         authenticateUser();
    //     }
    // }, [solanaWallets, authenticated, login]);


    return (
        <div className="w-60 h-full bg-gray-800 text-white flex flex-col">
            {/* Title */}
            <div
                className="text-center py-4 border-b border-gray-700 font-bold"
                style={{fontFamily: "Lato, sans-serif", fontSize: "24px"}}
            >

            </div>

            {/* Menu list */}
            <nav className="flex-1">
                <ul>
                    {menuItems.map((item) => (
                        <li key={item.path} className="w-full">
                            <Link
                                to={item.disable ? "#" : item.path}
                                className={`flex items-center gap-4 px-4 py-3 text-sm font-medium rounded-md ${
                                    item.disable
                                        ? "text-gray-500 cursor-not-allowed"
                                        : isActive(item.path)
                                            ? "bg-gray-700 text-blue-400"
                                            : "text-gray-300 hover:bg-gray-700 hover:text-white"
                                }`}
                                style={{
                                    fontFamily: "Lato, sans-serif",
                                    fontSize: "20px",
                                    pointerEvents: item.disable ? "none" : "auto",
                                }}
                            >
                                {item.icon}
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Wallet section - simplified to a single button */}
            {ready &&
                <div className="py-4 px-4 border-t border-gray-700">
                    {authenticated ? (
                        <div className="flex flex-col gap-3 p-2 bg-gray-700/50 rounded-lg border border-gray-600/50 shadow-inner">
                            <div className="flex items-center gap-3">
                                {/* User profile */}
                                <Tooltip title={chainAddress} placement="top">
                                    <div className="relative group">
                                        <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full opacity-70 group-hover:opacity-100 blur group-hover:blur-md transition duration-300"></div>
                                        <div className="w-11 h-11 bg-gradient-to-br from-blue-400 to-indigo-600 text-white font-bold rounded-full flex items-center justify-center relative ring-2 ring-gray-800 shadow-lg">
                                            {displayAddress}
                                        </div>
                                    </div>
                                </Tooltip>

                                {/* User status indicator */}
                                <div className="flex-1">
                                    <div className="text-sm font-medium text-gray-200">Connected</div>
                                    <div className="flex items-center text-xs text-gray-400">
                                        <span className="h-2 w-2 bg-emerald-500 rounded-full mr-1.5 animate-pulse"></span>
                                        Wallet Active
                                    </div>
                                </div>
                            </div>

                            {/* Logout button */}
                            <button
                                onClick={()=>logout()}
                                className="w-full flex items-center justify-center gap-1.5 bg-gray-600 hover:bg-gray-500 text-white font-medium px-3 py-1.5 rounded-md transition-all duration-300 shadow-md transform hover:translate-y-[-1px]"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                </svg>
                                <span>Logout</span>
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleWalletAction}
                            disabled={isConnecting}
                            className={`w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-medium py-3 rounded-lg transition-all duration-300 shadow-lg hover:shadow-blue-500/30 transform hover:translate-y-[-2px] flex items-center justify-center gap-2 ${isConnecting ? 'opacity-70 cursor-not-allowed' : ''}`}
                        >
                            {isConnecting ? (
                                <>
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Connecting...
                                </>
                            ) : (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    Connect Wallet
                                </>
                            )}
                        </button>
                    )}
                </div>
            }
        </div>
    );
};

export default Sidebar;
