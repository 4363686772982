import axios from 'axios';

// Remove prefix from base64 string
const stripBase64Prefix = (base64String: string) => {
    return base64String.replace(/^data:image\/[a-z]+;base64,/, '');
};

// Get signed URL for image upload
export const getOssSigned = () => {
    return axios.get('https://v1-6-0---sinebackend-vsy7hiaoca-uc.a.run.app/api/palm/signed-url');
};

// Upload image to storage
export const uploadImageToStorage = async (base64Image: string) => {
    try {
        // Get signed URL for upload
        const response = await getOssSigned();
        const signedUrl = response.data.data;
        console.log('signedUrl', signedUrl)

        // Convert base64 string to Blob object
        const binaryString = window.atob(stripBase64Prefix(base64Image));
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], {type: 'image/jpeg'});

        // Create File object from Blob
        const file = new File([blob], 'image.jpg', {type: 'image/jpeg'});

        // Upload File object to signed URL
        const uploadResponse = await fetch(signedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': 'image/jpeg'
            }
        });

        if (uploadResponse.ok) {
            // Extract image URL from signed URL
            return signedUrl.split('?')[0];
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};
