import React, {useEffect, useState} from "react";
import PokerTablePanel from "../components/PokerTablePanel";
import GameRecord from "../components/GameRecord";
import {GameState} from "../types/game";
import {collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../config/firebaseConfig";

const formatCountdown = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `Starts in ${hours} hr ${minutes} min ${secs}sec`;
};

const MainPage = () => {
    const [gameStartTime, setGameStartTime] = useState<number | null>(null);
    const [countdown, setCountdown] = useState<number>(0);
    const [gameState, setGameState] = useState<GameState | null>(null);

    // 添加倒计时效果
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (gameStartTime && gameState?.status !== 'active') {
            const updateCountdown = () => {
                const timeLeft = Math.max(0, Math.floor((new Date(gameStartTime).getTime() - Date.now()) / 1000));
                setCountdown(timeLeft);
            };

            // 立即更新一次
            updateCountdown();

            // 每秒更新一次
            timer = setInterval(updateCountdown, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [gameStartTime, gameState?.status]);

    useEffect(() => {
        // Get the latest active game
        const gamesQuery = query(
            collection(db, 'poker/prod/poker_games'),
            where('status', '==', 'active'),
            orderBy('last_updated', 'desc'),
            limit(1)
        );

        getDocs(gamesQuery).then((querySnapshot) => {
            console.log('Query results:', querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })));
            setGameState(querySnapshot.docs[0].data() as GameState);
        }).catch((error) => {
            console.error('Error getting documents:', error);
        });

        // Listen for the latest active game
        const unsubscribeGames = onSnapshot(gamesQuery, (snapshot) => {
            // 查询倒计时
            const startTimeRef = doc(db, `poker/prod/game_settings/start_time`);
            getDoc(startTimeRef).then((docSnapshot) => {
                if (docSnapshot.exists()) {
                    const utcStartTime = docSnapshot.data().start_time;
                    const utcDate = new Date(utcStartTime);
                    // 获取本地时区偏移量（分钟）
                    const timezoneOffset = utcDate.getTimezoneOffset();
                    // 转换为本地时间（毫秒）
                    const localStartTime = utcDate.getTime() - (timezoneOffset * 60 * 1000);
                    setGameStartTime(localStartTime);
                }
            }).catch((error) => {
                console.error('Error getting start time:', error);
            });
        });

        // Return cleanup function for games listener
        return () => {
            unsubscribeGames();
        };
    }, []);
    return (
        <div className="flex h-full">
            {/* 游戏区域 */}
            <div className="flex-1 flex flex-col">
                {/* 顶部状态栏 */}
                <div className="flex items-center justify-between px-6 py-3">
                    <div className="flex items-center gap-3">
                        <div
                            className="px-2 py-1 bg-emerald-900/30 rounded-md border border-emerald-500/20 text-emerald-400 text-xs">
                            <div className="flex items-center space-x-2">
                                <div className="h-2 w-2 bg-red-500 animate-pulse rounded-full"></div>
                                <span className="text-sm text-white">LIVE</span>
                            </div>
                        </div>
                        <span className={`${gameState?.status ? 'text-green-500' : (gameStartTime && countdown > 0 ? 'text-[#E86860]' : 'text-green-500')}`}>
                            {gameState?.status ?
                                gameState.status.toUpperCase() :
                                (gameStartTime && countdown > 0 ?
                                        formatCountdown(countdown) :
                                        'Starting Soon'
                                )
                            }
                        </span>
                    </div>
                    <div className="bg-[#D1DAFD] px-6 py-1.5 rounded-full border-4 border-[#3F5EDD]">
                        <span className="text-lg font-bold  text-black">Total Pot: ${gameState?.pot || 0}</span>
                    </div>
                </div>

                {/* 游戏主区域 */}
                <div className="flex-1">
                    <div className="h-full rounded-lg overflow-hidden">
                        <PokerTablePanel/>
                    </div>
                </div>
            </div>

            {/* 右侧游戏记录 */}
            <div className="w-[300px] bg-black/90 rounded-l-[40px]">
                <div className="flex flex-col h-full">
                    {/* 标题栏 */}
                    <div className="flex items-center justify-between p-4 border-b border-gray-800">
                        <h2 className="text-lg font-medium text-white">Game record</h2>
                        <button className="text-gray-400 hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    {/* 记录内容区域 */}
                    <div className="flex-1 overflow-y-auto">
                        <GameRecord/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
