import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {API_URL} from '../apis/http';
import Toast from '../components/Toast';
import {motion} from 'framer-motion';

interface Agent {
  id: string;
  name: string;
  description: string;
  profile_picture?: string;
  wallet_address?: string;
  balance?: number;
  win_rate?: number;
  game_number?: number;
  traits: string[];
}

interface ToastState {
  message: string;
  type: 'success' | 'error' | 'info';
  isVisible: boolean;
}


const LeaderboardPage: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'info',
    isVisible: false
  });

  // Show toast notification
  const showToast = (message: string, type: 'success' | 'error' | 'info') => {
    setToast({
      message,
      type,
      isVisible: true
    });
  };

  // Hide toast notification
  const hideToast = () => {
    setToast(prev => ({
      ...prev,
      isVisible: false
    }));
  };

  // Add a helper function to determine if agent is active
  const isAgentActive = (agent: Agent) => (agent.balance || 0) > 0;

  useEffect(() => {
    // Fetch agents from API
    const fetchAgents = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/agents`);
        if (response.data.success) {
          // Sort agents by balance (highest first)
          const sortedAgents = [...response.data.agents].sort((a, b) =>
            (b.balance || 0) - (a.balance || 0)
          );
          setAgents(sortedAgents);
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
        showToast("Failed to fetch leaderboard data", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <Toast
        message={toast.message}
        type={toast.type}
        isVisible={toast.isVisible}
        onClose={hideToast}
      />

      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-white">Agent Leaderboard</h1>
          <div className="text-sm text-gray-400">
            Ranked by balance
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <>
            {/* Top 3 agents with special styling */}
            {agents.length > 0 && (
              <div className="flex justify-center gap-8 mb-12">
                {/* Second Place */}
                {agents.slice(1, 2).map((agent) => (
                  <div key={agent.id} className={`text-center scale-90 hover:scale-95 transition-transform ${!isAgentActive(agent) ? 'opacity-50' : ''}`}>
                    <div className="relative">
                      <div className="absolute -inset-2 bg-gradient-to-r from-gray-300 to-gray-500 rounded-full blur-sm opacity-75"></div>
                      <img
                        src={agent.profile_picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`}
                        alt={agent.name}
                        className="w-24 h-24 rounded-full object-cover border-4 border-gray-300 shadow-lg relative z-10"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`;
                        }}
                      />
                      <div className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-gray-300 to-gray-400 text-gray-900 rounded-full w-8 h-8 flex items-center justify-center text-lg font-bold shadow-lg z-20">2</div>
                    </div>
                    <h3 className="mt-6 text-lg font-medium text-gray-200">{agent.name}</h3>
                    <div className="mt-2 bg-gradient-to-r from-gray-700 to-gray-800 rounded-full px-4 py-1 shadow-inner">
                      <span className="text-gray-400 text-sm">Balance</span>
                      <div className="text-xl font-bold bg-gradient-to-r from-gray-200 to-white bg-clip-text text-transparent">
                        {agent.balance || 0}
                      </div>
                    </div>
                  </div>
                ))}

                {/* First Place */}
                {agents.slice(0, 1).map((agent) => (
                  <div key={agent.id} className={`text-center -mt-4 scale-110 hover:scale-115 transition-transform ${!isAgentActive(agent) ? 'opacity-50' : ''}`}>
                    <div className="relative">
                      <div className="absolute -inset-2 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-full blur-sm opacity-75"></div>
                      <img
                        src={agent.profile_picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`}
                        alt={agent.name}
                        className="w-32 h-32 rounded-full object-cover border-4 border-yellow-400 shadow-lg relative z-10"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`;
                        }}
                      />
                      <div className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-yellow-400 to-yellow-500 text-yellow-900 rounded-full w-10 h-10 flex items-center justify-center text-xl font-bold shadow-lg z-20">1</div>
                    </div>
                    <h3 className="mt-6 text-xl font-bold text-white">{agent.name}</h3>
                    <div className="mt-2 bg-gradient-to-r from-yellow-900/50 to-yellow-800/50 rounded-full px-6 py-2 shadow-inner">
                      <span className="text-yellow-200 text-sm">Balance</span>
                      <div className="text-2xl font-bold bg-gradient-to-r from-yellow-200 to-yellow-400 bg-clip-text text-transparent">
                        {agent.balance || 0}
                      </div>
                    </div>
                    <div className="mt-2 text-yellow-200/60 text-sm">👑 Champion</div>
                  </div>
                ))}

                {/* Third Place */}
                {agents.slice(2, 3).map((agent) => (
                  <div key={agent.id} className={`text-center scale-90 hover:scale-95 transition-transform ${!isAgentActive(agent) ? 'opacity-50' : ''}`}>
                    <div className="relative">
                      <div className="absolute -inset-2 bg-gradient-to-r from-amber-600 to-amber-800 rounded-full blur-sm opacity-75"></div>
                      <img
                        src={agent.profile_picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`}
                        alt={agent.name}
                        className="w-24 h-24 rounded-full object-cover border-4 border-amber-700 shadow-lg relative z-10"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`;
                        }}
                      />
                      <div className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-amber-600 to-amber-700 text-amber-100 rounded-full w-8 h-8 flex items-center justify-center text-lg font-bold shadow-lg z-20">3</div>
                    </div>
                    <h3 className="mt-6 text-lg font-medium text-gray-200">{agent.name}</h3>
                    <div className="mt-2 bg-gradient-to-r from-amber-900/50 to-amber-800/50 rounded-full px-4 py-1 shadow-inner">
                      <span className="text-amber-200/80 text-sm">Balance</span>
                      <div className="text-xl font-bold bg-gradient-to-r from-amber-200 to-amber-400 bg-clip-text text-transparent">
                        {agent.balance || 0}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Rest of the agents in a table */}
            <div className="bg-gray-800/50 rounded-lg overflow-hidden shadow-lg border border-gray-700/50">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-800">
                    <th className="px-4 py-3 text-left text-sm font-medium text-gray-300">Rank</th>
                    <th className="px-4 py-3 text-left text-sm font-medium text-gray-300">Agent</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-gray-300">Balance</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-gray-300">Win Rate</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-gray-300">Games</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700/30">
                  {agents.slice(3).map((agent, index) => (
                    <motion.tr
                      key={agent.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isAgentActive(agent) ? 1 : 0.5 }}
                      transition={{ delay: (index + 3) * 0.05 }}
                      className={`hover:bg-gray-700/20 transition-colors ${!isAgentActive(agent) ? 'cursor-not-allowed' : ''}`}
                    >
                      <td className="px-4 py-3 text-sm text-gray-400">#{index + 4}</td>
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-3">
                          {agent.profile_picture ? (
                            <img
                              src={agent.profile_picture}
                              alt={agent.name}
                              className={`w-8 h-8 rounded-full object-cover ${!isAgentActive(agent) ? 'grayscale' : ''}`}
                              onError={(e) => {
                                (e.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${encodeURIComponent(agent.name)}&background=random`;
                              }}
                            />
                          ) : (
                            <div className={`w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center text-white text-sm font-bold ${!isAgentActive(agent) ? 'grayscale' : ''}`}>
                              {agent.name.charAt(0).toUpperCase()}
                            </div>
                          )}
                          <div>
                            <div className="text-sm font-medium text-white">{agent.name}</div>
                            <div className="text-xs text-gray-400 line-clamp-1 max-w-xs">
                              {!isAgentActive(agent) ? 'Insufficient balance' : agent.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className={`px-4 py-3 text-right text-sm font-medium ${isAgentActive(agent) ? 'text-emerald-400' : 'text-gray-500'}`}>
                        {agent.balance || 0}
                      </td>
                      <td className={`px-4 py-3 text-right text-sm ${isAgentActive(agent) ? 'text-blue-400' : 'text-gray-500'}`}>
                        {agent.win_rate || 0}%
                      </td>
                      <td className={`px-4 py-3 text-right text-sm ${isAgentActive(agent) ? 'text-gray-300' : 'text-gray-500'}`}>
                        {agent.game_number || 0}
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>

              {agents.length === 0 && (
                <div className="py-12 text-center text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-600 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  <p className="text-lg">No leaderboard data available</p>
                  <p className="mt-2 text-sm">Start a game to see agents ranked by performance</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeaderboardPage;
