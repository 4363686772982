import React, {useEffect, useState} from "react";
import {collection, doc, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../config/firebaseConfig";

interface GameState {
    game_id: string;
    hand_number: number;
    round_name: string;
    players: PlayerState[];
    status: string;
}

interface PlayerState {
    player_id: number;
    name?: string;
    profile_picture?: string;
}

interface GameLogEntry {
    game_id: string;
    timestamp: number;
    log_type: string;
    message: string;
    player_id?: string;
    firebase_id?: string;
    hand_number?: number;
    round_name?: string;
    sequence_number: number;
}

interface GameLog {
    timestamp: number;
    message: string;
    player_id: string;
    firebase_id: string;
    type: 'thinking' | 'action' | 'error' | 'system';
}

interface Agent {
    id: string;
    name: string;
    profile_picture: string;
}

const GameRecord = () => {
    const [gameState, setGameState] = useState<GameState | null>(null);
    const [gameLogs, setGameLogs] = useState<GameLog[]>([]);
    const [agents, setAgents] = useState<{ [key: string]: Agent }>({});
    const [selectedType, setSelectedType] = useState<'all' | 'thinking' | 'action' | 'error' | 'system'>('all');

    useEffect(() => {
        // 获取agents数据
        fetch('https://rps-agent-backend-954067898723.us-central1.run.app/agents')
            .then(response => response.json())
            .then(data => {
                const agentsMap = data.agents.reduce((acc: { [key: string]: Agent }, agent: Agent) => {
                    acc[agent.id] = agent;
                    return acc;
                }, {});
                setAgents(agentsMap);
            })
            .catch(error => console.error('Error fetching agents:', error));

        const gamesQuery = query(
            collection(db, 'poker/prod/poker_games'),
            where('status', '==', 'active'),
            orderBy('last_updated', 'desc'),
            limit(1)
        );

        const unsubscribe = onSnapshot(gamesQuery, (snapshot) => {
            if (!snapshot.empty) {
                const latestGame = snapshot.docs[0];
                const gameId = latestGame.id;

                // 监听游戏状态
                const gameStateQuery = doc(db, `poker/prod/poker_games/${gameId}`);
                onSnapshot(gameStateQuery, (stateSnapshot) => {
                    if (stateSnapshot.exists()) {
                        const currentState = stateSnapshot.data().current_state as GameState;
                        setGameState(currentState);
                    }
                });

                // 监听游戏日志
                const logsQuery = query(
                    collection(db, 'poker/prod/game_logs', gameId, 'entries'),
                    orderBy('timestamp', 'desc')
                );

                onSnapshot(logsQuery, (logsSnapshot) => {
                    const logEntries = logsSnapshot.docs.map(doc => doc.data() as GameLogEntry);
                    // console.log('Game logs received:', logEntries);

                    // Convert to our GameLog format
                    const formattedLogs = logEntries.map(entry => ({
                        timestamp: entry.timestamp,
                        message: entry.message,
                        player_id: entry.player_id ? entry.player_id : '',
                        firebase_id: entry.firebase_id ? entry.firebase_id : '',
                        type: mapLogTypeToDisplayType(entry.log_type).toLowerCase()
                    } as GameLog));

                    // Update logs
                    setGameLogs(formattedLogs);
                });
            }
        });

        return () => unsubscribe();
    }, []);

    // Helper function to map log types
    const mapLogTypeToDisplayType = (logType: string): 'thinking' | 'action' | 'error' | 'system' => {
        switch (logType?.toUpperCase()) {
            case 'REASONING':
                return 'thinking';
            case 'ACTION':
                return 'action';
            case 'ERROR':
                return 'error';
            case 'CHAT':
                return 'action';
            case 'RESULT':
            case 'SYSTEM':
            default:
                return 'system';
        }
    };

    const filteredLogs = selectedType === 'all'
        ? gameLogs
        : gameLogs.filter(log => {
            const logType = typeof log.type === 'string' ? log.type.toLowerCase() : log.type;
            return logType === selectedType;
        });

    return (
        <div className="space-y-4">
            {/* 标签和过滤器 */}
            <div className="p-4 space-y-4">
                {/* 标签栏 */}
                <div className="flex justify-between border-b border-gray-800 pb-2">
                    <span className="text-gray-500 select-none">Game</span>
                    <span className="text-white">Thought</span>
                </div>

                {/* 过滤器 */}
                <div className="relative">
                    <select
                        className="w-full bg-[#1B1B1B] text-white rounded-lg px-4 py-2.5 appearance-none cursor-pointer text-lg"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value as typeof selectedType)}
                    >
                        <option value="all">All</option>
                        <option value="thinking">Thinking</option>
                        <option value="action">Action</option>
                        <option value="error">Error</option>
                        <option value="system">System</option>
                    </select>
                    <div className="absolute right-4 inset-y-0 flex items-center pointer-events-none">
                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
                        </svg>
                    </div>
                </div>

                <div className="flex items-center justify-center text-xs text-gray-500 mt-4">
                    <div className="flex items-center gap-4 whitespace-nowrap">
                        <div className="w-[60px] h-[1px] bg-gray-800"/>
                        {gameState &&
                            <span>Hand {gameState?.hand_number || 5}, Round {gameState?.round_name || 3}</span>}
                        <div className="w-[60px] h-[1px] bg-gray-800"/>
                    </div>
                </div>
            </div>

            {/* 消息列表 */}
            <div className="space-y-4 px-4">
                {filteredLogs.map((log, index) => {
                    const agent = log.firebase_id ? agents[log.firebase_id] : null;
                    return (
                        <div key={index} className="flex items-start gap-3">
                            {!log.firebase_id && (
                                <img
                                    src='/live-logo.png'
                                    alt="System"
                                    className="w-8 h-8 rounded-full flex-shrink-0"
                                />)}
                            {log.firebase_id && (
                                <img
                                    src={agent?.profile_picture || `https://api.dicebear.com/7.x/pixel-art/svg?seed=Player${log.firebase_id}`}
                                    alt={agent?.name || `Player ${log.firebase_id}`}
                                    className="w-8 h-8 rounded-full flex-shrink-0"
                                />)}
                            <div className="flex-1">
                                <div className="bg-[#1B1B1B] rounded-2xl p-3">
                                    <p className="text-sm text-white">{log.message}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GameRecord;
